:root{
  --btn-bg: rgb(41, 10, 52);
}
::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: rgba(25, 12, 43, 0.909);
}
::-webkit-scrollbar-button{
  background: rgb(15, 2, 23);
}
::-webkit-scrollbar-thumb{    
  background: 
  linear-gradient(
  145deg,
  rgb(96, 30, 153) 10%,
  rgb(23, 2, 43) 60%
  );
  border-radius: 50px;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  /* background-image: url(assets/images/I_was_alone.png); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.btn-game{
  position: relative;
  display: block;
  background: 
  linear-gradient(
    135deg,
    rgb(148, 70, 167) 10%,
    rgb(28, 1, 31) 70%
    );
  z-index: 1;
  padding: 10px 15px;
  border-radius: 10px;
  transition: 0.3s ease-in-out all;
}
.btn-game:hover{
  transform: scale(1.01) translateY(-3px);
  background:
  linear-gradient(
    150deg,
    rgba(193, 103, 216, 0.8) 10%,
    rgba(56, 4, 62, 0.927) 70%
    );
}

.btn-game::before{
  position: absolute;
  background: 
  linear-gradient(
    135deg,
    rgba(167, 70, 144, 0.8) 10%,
    rgba(213, 11, 45, 0.927) 70%
    );
  z-index: 1;
  content: "NEW!";
  margin-left: -30px; 
  margin-top: -25px;
  text-align: center;
  font-size: 13px;
  padding: 5px;
  border-radius: 10px;
  animation: btn-animation infinite 1s linear;
}

.btn-shade::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease-in-out;
  transform: 
  translateY(5px) 
  translateX(-50%);
  border-radius: 10px;
  display: inline-block;
}
.btn-shade-container:has(.btn-game:hover){
  .btn-shade::before{
    opacity: 1;
    content: "";
    position: absolute;
    z-index: 0;
    background:
    linear-gradient(
      135deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
    filter: blur(5px);
    animation: shade-animation infinite 1s linear;
  }
}

@keyframes btn-animation {
  0%{
    background: 
    linear-gradient(
      0deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  12.5%{
    background: 
    linear-gradient(
      45deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  25%{
    background: 
    linear-gradient(
      90deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  37.5%{
    background: 
    linear-gradient(
      135deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  50%{
    background: 
    linear-gradient(
      180deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  62.5%{
    background: 
    linear-gradient(
      225deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  75%{
    background: 
    linear-gradient(
      270deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  87.5%{
    background: 
    linear-gradient(
      315deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
  100%{
    background: 
    linear-gradient(
      360deg,
      rgba(167, 70, 144, 0.8) 10%,
      rgba(213, 11, 45, 0.927) 70%
      );
  }
}

@keyframes shade-animation {
  0%{
    background: 
    linear-gradient(
      0deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  12.5%{
    background: 
    linear-gradient(
      45deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  25%{
    background: 
    linear-gradient(
      90deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  37.5%{
    background: 
    linear-gradient(
      135deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  50%{
    background: 
    linear-gradient(
      180deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  62.5%{
    background: 
    linear-gradient(
      225deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  75%{
    background: 
    linear-gradient(
      270deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  87.5%{
    background: 
    linear-gradient(
      315deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
  100%{
    background: 
    linear-gradient(
      360deg,
      rgba(193, 171, 236, 0.8) 10%,
      rgba(94, 60, 216, 0.817) 50%
      );
  }
}