:root{
    bg-picture-art: red;
}

*{margin:0}

.card_art_container{
    display: inline-block;
    position: relative;
    width: auto;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;


}

.card_body{
    /* background-color: salmon; */
    width: 20vw;
    height: 20vh;
    display: table-cell;
    overflow: hidden;
    & img {
        object-fit: cover;
        position: relative;
        display: block;
        transition: 0.3s ease-in;
        border-color: rgb(160, 129, 230);
        background:
            linear-gradient(
                135deg,
                rgba(187, 36, 99, 0.43) 10%,
                rgb(36, 1, 1) 70%
            );

    }
    &:hover img{
        transform: scale(1.2);
        filter: brightness(0.1);
    }


    & small{
        position:fixed;
        transition: 0.3s ease-in-out;
        display: block;
        margin-top: 45%;
        width: 100%;
        z-index: 1;
        opacity: 0;
    }
    &:hover small{
        opacity: 1;
    }
}

.type_one{
    transform: translateY(13px);
    width: clamp(100px,250px, 500px);
    height:clamp(150px,300px, 550px);
    border-width: 3px;
    border-style: dashed;
    & img{

        width: clamp(100px,250px, 500px);
        height:clamp(150px,300px, 550px);
    }
}
.type_two{
    transform: translateY(7px);
    width: clamp(100px,250px, 500px);
    height:clamp(100px,250px, 500px);
    border-style: groove;
    border-width: 3px;
    & img{
        height:clamp(100px,250px, 500px);
        width: clamp(100px,250px, 500px);
    }
}

