:root{
    --bg-color: rgb(7, 3, 12);
    --avg-section-body-color: rgba(7, 3, 12, 0.299);
}
*{
    margin:0;
    scroll-behavior: smooth;
}

.main-home{
    background-color: var(--bg-color);
    height: 100vh;
    width: auto;
}
.hor-container{
    display: flex;
    width: auto;
    height: auto;
}
.ver-container{
    display:block;
    width: auto;
    height: auto;
}

.elements-container{
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
}


/* sections---------------- */
.section-home{
    width:auto;
    height: auto;
}
#section1{
    padding-top: 10vh;
    padding-bottom: 10vh;
    height: 80vh;
    background-image: url(../images/home_section1_bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
#section1::before{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    margin-top: -72px;
    background:
        linear-gradient(
            140deg,
            rgba(21, 4, 39, 0.368) 10%,
            rgb(12, 1, 25) 80%
        );
}
/* section 1---------------------------- */
.header-page{
    margin-top: 5rem;
    display: flex;
    height: 60vh;
    padding-top: 5rem;
    overflow: hidden;
    border-radius: 20px;
    position:relative;
    box-shadow: 30px 30px 30px 5px rgb(6, 1, 20);
}
.header-page::before{
    content: "";
    position: absolute;
    z-index: 2;
    height: 111%;
    width: 101%;
    margin-top: -8%;
    background:
        linear-gradient(
            150deg,
            rgba(21, 4, 39, 0) 60%,
            rgba(12, 1, 25, 0.842) 90%
        );
    filter: blur(10px); 
}
.header-title-container{
    text-align: left;
    padding: 10px 15px;
    animation: on-view-element linear;
    animation-timeline: view();
    animation-range-start: 0px;
    animation-range-end: 200px;
    width: 30%;
    display: table;
    font-family: Calibri;  
    margin-top: 10%; 
    z-index: 2;
}
.header-title-container h1{
    font-size: 30px;
}
.header-title-container h2{
    font-size: 20px;
}
.generic-btn-container{
    position: absolute;
    z-index: 9;
    right: 80px;
    margin-top: 30rem;
    animation: on-view-element linear;
    animation-timeline: view();
    animation-range-start: 0px;
    animation-range-end: 100px
    /* display: none; */
}

.email-btn{
    gap: 10px;
    padding: 3px 10px;
    border-radius: 50px;
    margin-top:30px;
    transition: 0.3s ease-in-out;
    background: 
    linear-gradient(
        135deg, 
    rgba(183, 16, 16, 0.564) 10%,
    rgba(14, 3, 96, 0.741) 90%

    );
    & svg{
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: -3px;
    }

    &:hover {
        background-color: rgb(47, 207, 19);
    }
}

.header-carousel-container{
    /* background-color: rgba(85, 14, 14, 0.4); */
    content: "x";
    width: 35vw;
    height: 20vh;
    z-index: 1;
    rotate: 15deg;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    /* animation: outer-rotate infinite 20s linear; */
}


.picture-header{
    display: table;
    position:fixed;
    width: 280px;
    height: 325px;
    margin: 5px;
    border-radius: 10px;
    rotate: -15deg;
    overflow: hidden;
    background-color: rgb(62, 27, 27);
    border: 1px dashed rgba(135, 90, 156, 0.566);
    /* z-index: 5; */
}
#img1{
    margin-left: -2vw;
    margin-top: -2vh;
    animation: counter-rotate infinite 20s linear;
    animation-delay: 0s; 
}
#img2{
    margin-left: -2vw;
    margin-top: -2vh;
    animation: counter-rotate infinite 20s linear; 
    animation-delay: 4s; 
}
#img3{
    margin-left: -2vw;
    margin-top: -2vh;
    animation: counter-rotate infinite 20s linear; 
    animation-delay: 8s; 
}
#img4{
    margin-left: -2vw;
    margin-top: -2vh;
    animation: counter-rotate infinite 20s linear; 
    animation-delay: 12s; 
}
#img5{    
    margin-left: -2vw;
    margin-top: -2vh;
    animation: counter-rotate infinite 20s linear; 
    animation-delay: 16s; 
}

.sct1-carousel-img{
    position: relative;
    display: inline-block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.butons-to-sections{
    &{
        position:  absolute;
        width: 100%;
        z-index: 8;
        bottom: 0%;
        padding-top: 10px;
        height: 15%;
        background-color:  rgba(31, 7, 49, 0.817);
        mask:
        linear-gradient(
            135deg,
            transparent,
            white 20%,
            white 30%,
            transparent
        );
        backdrop-filter: blur(5px);
    }
   & ul{
        list-style: none;
        position: relative;
        display: inline-flex;
        gap: 10px;
        margin-left: auto;
        margin-right: auto;
    }
    &ul li{
        margin: 0px 10px;
        transition: 0.3s
    }
    & ul li:hover{
        color:aqua;
        scale: 1.05;
    }
}
/* section 2---------------------------- */
#section2, #section3, #section4{
    width: auto;
    height: auto;
    padding-top: 10vh;
    padding-bottom: 10vh;
    min-height: 100vh;
    background: 
        linear-gradient(
            130deg,
             rgb(41, 4, 65) 5%,
            var(--bg-color) 50%
        );
}
.inner-section-container {
    display: table;
    width: 80%;
    height: 90%;
    padding: 10px 15px;
    margin-left: auto;
    margin-right: auto;
    background: 
    linear-gradient(
        130deg,
        rgba(18, 3, 32, 0) 10%,
        var(--avg-section-body-color) 70%
    );
    border-radius: 10px;
    backdrop-filter: blur(3px);
    box-shadow: 10px 5px 7px 1px rgb(16, 2, 31);
    animation: on-view-element linear;
    animation-timeline: view();
    animation-range-start: 0px;
    animation-range-end: 250px;
    break-after: auto;
    hyphens: auto;
}

.inner-section-container h1 {
    font-size: 18px;
    animation: fade-titles;
    animation-timeline: view();
    animation-range-start: 0px;
    animation-range-end: 100px;
}
.first-aboutme {
    text-align: left;
    animation: aboutme-load-contianer linear;
    animation-timeline: view();
    animation-range-start: 0px;
    animation-range-end: 300px;
}
.first-aboutme h2{
    text-align: left
}
.first-aboutme h3{
    font-size: 14px;
}

.second-aboutme{
    margin-top: 5vh;
    text-align: left;
    position: relative;
    animation: aboutme-load-contianer2 linear;
    animation-timeline: view();
    animation-range-start: 0px;
    animation-range-end: 300px;
}
.hobbies-list{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: auto;
    gap: 50px;
}
.hobbies-list .hobby_card{
    position: relative;
    display: inline-table;
    margin-left: 10px;
    margin-right: 10px;
    width: 10vw;
    height: 10vh;
    padding: 10px 10px;
    border: 1px dashed var(--avg-section-body-color);
    border-radius: 20px;
    background: 
        linear-gradient(
            150deg,
            rgba(45, 10, 77, 0.037) 20%,
            rgba(5, 0, 13, 0.8) 80%
        );
    transition: 0.3s ease-in;

}

.hobbies-list .hobby_card span{
    position: fixed;
    display: table;
    width: 150px;
    height: 150px;
    padding: 5px;
    border-radius: 10px;
    /* translate: 3vw; */
    z-index: 1;
    top: -50%;
    background:
    linear-gradient(
        135deg,
        rgb(95, 43, 122) 40%,
        rgba(2, 2, 55, 0.386) 80%
    );
    backdrop-filter: blur(50px);
    transition: 0.5s ease-out;
    box-shadow: 0px 1px 2px 1px rgba(9, 2, 19, 0.2);
    opacity: 0;
}
.hobbies-list .hobby_card span::before{
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: -1;
    bottom: -35%;
    margin-left: 25%;
    margin-right: 40%;
    border-radius: 10px;
    background-color: rgba(112, 64, 64, 0);
    border-left: 30px solid rgba(68, 68, 188, 0);
    border-top: 30px solid rgba(13, 13, 82, 0.505);
    border-right: 30px solid rgba(0, 0, 255, 0);
    border-bottom: 30px solid rgba(0, 0, 255, 0);
}

.hobbies-list .hobby_card:hover{
    background-color: rgb(9, 9, 48);
    
}
.hobbies-list .hobby_card:hover span{
    opacity: 1;
    top: -100%;
}


/*-------------------- SECTION 3------------------- */
.inner-section-infinitescroll{
    width: auto;
    height: auto;
    content: "";
    mask:
    linear-gradient(
        90deg,
        transparent,
        white,
        white,
        transparent
    );
    position: relative;
    display: block;
    overflow: hidden;
    padding: 10px 15px;
}
.item-cont-sroll{
    width: 80vw;
    overflow: hidden;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
}


.skill-scroll1{
    display: flex;
    gap: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(270px*9);
    animation: auto-linear-scroll 30s infinite linear;
}

.img-skill-cont{
    height: 250px;
    width: 250px;
    overflow: hidden;
    border-radius: 10px;
    flex-shrink: 0; /* Evita que se reduzca */
    background-color: rgba(42, 10, 75, 0.361);
    position: relative;
    content: "";
}
.img-skill{
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s linear;
}
.img-skill-cont span{
    position: absolute;
    opacity: 0;
    text-align: center;
    color: rgb(255, 255, 255);
    z-index: 1;
    top: 45%;
    transform: translateX(-50%);
    width: 100%;
    transition: 0.3s linear;
}
.img-skill-cont:hover{
    .img-skill{
        scale: 1.1;
        background-color: black;
        opacity: 0.3;
        filter: blur(5px);
    }
    span{
        opacity: 1;
    }
}

.skill-scroll2{
    display: flex;
    gap: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(270px*9);
    animation: auto-linear-scroll-reverse 30s infinite linear;

}
.item-cont-sroll{
    &:hover div{
        animation-play-state: paused;
    }
}

/* SECTION 4 */
.hardskill-scroll1{
    display: flex;
    gap: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(270px*25);
    animation: hardskill-scroll1 70s infinite linear;
}
.hardskill-scroll2{
    display: flex;
    gap: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(270px*25);
    animation: hardskill-scroll2 70s infinite linear;

}
/* Animations  --------------------------------*/
@keyframes hardskill-scroll1{
    from{
        transform: translateX(0);
    }
    to{
        transform: translateX(calc(-270px*25));
    }
}
@keyframes hardskill-scroll2{
    from{
        transform: translateX(calc(-270px*25));
    }
    to{
        transform: translateX(0);
    }

}
@keyframes auto-linear-scroll{
    from{
        transform: translateX(0)
    }
    to{
        transform: translateX(calc(-270px*9));
    }
}
@keyframes auto-linear-scroll-reverse{
        from{
            transform: translateX(calc(-270px*9));
        }
        to{
            transform: translateX(0px);
        }
}
@keyframes aboutme-load-contianer{
    0%{
        transform: translateX(-300px);
        opacity: 0;
        background-color: var(--avg-section-body-color);
        border-radius: 10px;
    }
    50%{
        transform: translateX(-300px);
        opacity: 0;
        background-color: var(--avg-section-body-color);
        border-radius: 10px;
    }
    to{
        opacity: 1;
    }
}
@keyframes aboutme-load-contianer2{
    0%{
        transform: translateX(300px);
        opacity: 0;
        width: 50px;
        background-color: var(--avg-section-body-color);
        border-radius: 10px;
    }
    50%{
        transform: translateX(300px);
        opacity: 0;
        width: 50px;
        background-color: var(--avg-section-body-color);
        border-radius: 10px;

    }
    to{
        opacity: 1;
    }
}
@keyframes fade-titles {
   from{
    opacity: 0;
    scale: 0.5;
    margin-top: -10px,
   } 
   to{
    opacity: 1;
    scale: 1.0;
    margin-top: 0;
   }
}
@keyframes on-view-element{
    from{
        filter: blur(2px);
        opacity: 0.4;
        scale: 0.7;
        border-radius: 50px;
    }
    to{
        filter: blur(0px);
        opacity: 1;
        scale: 1;
        border-radius: 10px;
    }
}
@keyframes outer-rotate{
    0% {
        transform: rotateZ(0deg);
    }

    100%{
        transform: rotateZ(360deg);
    }
}
@keyframes counter-rotate{
    0%{
        top:0%;
        left:0%;
        z-index: 0;
        transform: scale(1.0);
    }
    25%{
        top:0%;
        left:100%;
        z-index: 0;
        transform: scale(1.0);
    }
    50%{
        top: 100%;
        left: 100%;
        z-index: 1;
        transform: scale(1.0);
    }
    75%{
        top: 100%;
        left: 0%;
        z-index: 2;
        transform: scale(1.1);
    }
    100%{
        top:0%;
        left:0%;
        z-index: 1;
        transform: scale(1.0);
    }
}


/* screen queries--------------------------------- */
@media only screen and (width < 650px) {
    .header-page{
        display: block;
        margin-top: -2rem;
    }
    .header-title-container{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        margin-top: -3rem;
        transform: translateY(14rem);
        text-align: center;
        z-index: 9;
    }
    .header-carousel-container{
        width: auto;
    }
    .generic-btn-container{
        margin-top: 29rem;
    }
    .hobbies-list .hobby_card:hover span{
        margin-bottom: -50%;
    }
    #section2,#section3{
        height: auto;
    }
    .hobbies-list li {
        width: content;
    }
    .hobbies-list li span{
        top: 10%;
    }
    .hobbies-list li:hover span{
        top: 20%
    }
}