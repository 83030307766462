
.footer-body{
    position:relative;
    display: table;
    width: 100%;
    height: 10%;
    background-color: rgb(8, 1, 16);
    bottom: 0;
    background:
    linear-gradient(
        125deg,
        rgb(7, 1, 24) 10%,
        rgb(0, 0, 0) 60%
    );
}
.generic-section-container{
    padding: 10px;
    display:table;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 10px;
}
.container-horizontal{
    display: flex;
    height: auto;
}
.container-vertical{
    display: block;
    height: auto;
}

.btn-list{
    margin-top: 30px;
    margin-bottom: 30px;
    list-style: none;
    display: flex;
    gap: 10px; /* Espacio entre elementos */
    margin-left: auto;
    margin-right: auto;
}

.btn{
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-table;
}
.btn span{
    margin-left: 10px;
}
.btn-socialmedia{
    background-color: rgba(34, 2, 35, 0.3);
}

.footer-to-bottom{
    position: absolute;
    width: 100%;
    bottom: 0;
    color: black;
}