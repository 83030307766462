:root{
    --bg_light: rgb(5, 5, 41);
    --bg_base: rgba(7, 2, 31, 0.271);
}
*{
    margin: 0,
}


.header-menu {
    width: 100%;
    height: 60px;
    position:fixed;
    display: flex;
    z-index: 10;
    background-color: rgba(43, 3, 52, 0.484);
    background: 
    linear-gradient(178deg, var(--bg_light) 10%, var(--bg_base) 60%);
    backdrop-filter: blur(3px);
    box-shadow: 0px 0px 20px 5px rgba(7, 2, 31, 0.271);

}
.container-logo{
    display: inline-flex;
    width: 40%;
}
.icon-nav-menu{
    display: table;
    margin: 13px 15px;
    width:30px;
    height:30px;
}
.icon-navmenu-png{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.container-name-title{
    width: auto;
    position: relative;
    display: grid;
    padding-top: 5px;
}
.container-name-title span{
    width:auto;
    text-align: left;
    font-size: 12px;
    display: contents;
    background-color: red;
}
.container-name-title small{
    width:auto;
    text-align: left;
    font-size: 10px;
    margin-top: -20px;
}

.ul-menu-buttons{
    list-style: none;
    display:flex;
    gap: 1vw;
    margin-left: auto; 
    margin-right: auto;  
}

.ul-menu-buttons li{
    display: block;
}

.label-btn{
    display: flex;
    padding-top: 18px;
    padding-right: 10px;
    padding-left: 10px;
    width: auto;
    height: 80%;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    border-radius: 5px;
}


.label-btn svg{
    margin: -10px -5px;
    opacity: 0;
    transform: scale(0.5);
    transition: 0.3s ease-in;
}


.label-btn span{
    font-size: 12px;
    word-break: break-all;
}

.label-btn:hover{
    background-color: rgba(106, 59, 120, 0.2);
    backdrop-filter: blur(3px);
    box-shadow: 1px 1px 5px 2px rgba(63, 53, 107, 0.3);
}

.label-btn:hover svg{
    margin: 0px 5px;
    opacity: 1;
    transform: scale(0.7);
}

.ul-menu-buttons li:has(input[type="radio"]:checked){
    .label-btn{
        transform: scale(1.01) translateY(5px);
        background-color: rgba(22, 11, 74, 0.162);
        backdrop-filter: blur(10px);
        box-shadow: 1px 1px 5px 2px rgba(16, 8, 52, 0.162);
    }
    .label-btn svg{
        margin-top: 15px;
        transform: scale(1) translateX(100%);        
        opacity: 1;
    } 
    .label-btn:hover svg{
        margin: 15px -5px;
    } 
}

@media  only screen and (width < 650px){
    .header-menu{
        display:block;
        height: 90px;
    }
    .icon-nav-menu{
        width: 30px;
        height: 30px;
    }
    .container-logo{
        width: auto;
        display: flex;
    }
    .icon-nav-menu{
        width: 15px;
        height: 15px;
    }
    .container-name-title span{
        font-size: 10px;
    }
    .container-logo small{
        margin-top: -5px;
        font-size: 5px;
    }
    .ul-menu-buttons{
        display: inline-flex;
    }
    .label-btn{
        margin-left: -10px;
    }
    .label-btn span {
        font-size: 8px;
    }
}