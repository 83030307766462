:root{
    --card_color:rgba(13, 8, 18, 0.717);
    --game_bg_color: rgba(5, 1, 13, 0.893);
    --light_gradient: rgba(129, 109, 202, 0.269);
    --border_light:rgba(120, 98, 173, 0.7);
    --visiblity-test-red: rgba(255, 0, 0, 0.372);
    --visiblity-test-blue: rgba(128, 0, 255, 0.372);
    --steam-color:rgb(33, 33, 29);
    --xbox-color:rgb(62, 62, 31);
    --epic-color:rgb(45, 24, 62);
    --playstore-color:rgb(46, 14, 96);
    --itch-color:rgb(193, 44, 44);
    --btn-social-media-color:rgba(20, 3, 30, 0.776);
    --privacy_bg: rgba(241, 235, 245, 0.973);
    --privacy_normal_font: rgb(0, 0, 0);
    --privacy_title_font: rgb(59, 23, 99);
}

*{
    margin:0px;
    color: white;
}
.Line{
    margin:15px 25px;
}
.Game-container{
    background:linear-gradient(
        160deg,
        var(--light_gradient)4%,
        var(--game_bg_color)90%);
    background-image: url("../../assets/images/I_was_alone.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    height: auto;
    width: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}
h1{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif,
}
h2{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.Game{
    background:linear-gradient(
        160deg,
        var(--light_gradient)5%,
        var(--game_bg_color)90%);
    background-color: var(--card_color);
    background-position: center;
    backdrop-filter: blur(5px);
    box-shadow: 3px 3px 7px 5px var(--border_light);
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;   
    border-radius: 10px;
    padding: 10px;
    width: 70vw;
    height: auto;
}

.generic-section-container{
    width: auto;
    padding: 10px;
    display:contents;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
}
.container-horizontal{
    display: flex;
    height: auto;
}
.container-vertical{
    display: block;
    height: auto;
}

/* top card */
/* colum right -------------------- */
.div-image{
    width: 50%;
    height: auto;
}

.div-short-text{
    width: 50%;
    height: auto;
}
.div-complete{
    width: auto;
    height: auto;
    overflow: hidden;
    padding: 10px 30px;
    text-align: left;
}

.picture_image, .picture_image_game_features{
    background-color: var(--visiblity-test-blue);
    display: block;
    width: clamp(150px, 200px, 250px);
    height: clamp(150px, 200px, 250px);
    border-radius: 5px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    transition: 0.3s ease-in;
}

.picture_image p {
    position: relative;
    opacity: 0;
    display: table;
    margin-left: auto;
    margin-right: auto;
    word-wrap: break-word;
    width: clamp(100px, 150px, 200px);
    margin-top: -63%;
    transition: 0.3s ease-in-out;
    text-align: center;
}

.image-begining{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s ease-in;
}

.picture_image:hover{
    .image-begining{
        scale: 1.1;
        filter: blur(5px) brightness(0.5);
    }
}
.picture_image:hover p{
    opacity: 1;
}

#hr-divisor-btn{
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
/* column left ------------------------------------- */
.picture_image_shortheader{
    display: block;
    border-radius: 10px 10px 0px 0px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    height: 30%;
}
.image-shortheader{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.short-text-container{
    width: 70%;
    height: 60%;
    display: block;
    word-wrap: break-word;
    text-align: left !important;
    margin-left: auto;
    margin-right: auto;
    hyphens: auto;
}
.div-short-text h2{
    text-align: center
}
.div-complete ul{
    display: table;
    margin-left: auto;
    margin-right: auto;
}
.div-complete ul li{
    margin: 10 0px;
}
.picture_image_game_features{
    width: 70%;
    display: table;
    margin-left: auto;
    margin-right: auto;
}
.picture_image_game_features label{
    margin-top: -35%;
    position: relative;
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transition: 0.3s;
}
.picture_image_game_features:hover img{
    scale: 1.1;
    filter: blur(5px) brightness(0.3);
}
.picture_image_game_features:hover label{
    opacity: 1;
}
/* images ---------------------------------- */
.multiple-media-images-container{
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    height: clamp(250px, 300px, 350px);
    background-color: var(--visiblity-test-blue);
}

.quick-carousel{
    position: relative;
    height: 100%;
    width: 25%;
    object-fit: cover;
    margin-left: -13px;
    margin-right:-13px;
    transition: 0.3s;
}
.quick-carousel:hover{
    z-index: 1;
    height: 105%;
    width: 50%;
    margin-left: -15%;
    margin-right: -15%;
    transform: translateY(-5%) ;
    box-shadow: 0px 0px 2px 2px var(--border_light);   
}

.btn-list{
    margin-top: 30px;
    margin-bottom: 30px;
    list-style: none;
    display: flex;
    gap: 10px; /* Espacio entre elementos */
    margin-left: auto;
    margin-right: auto;
}

.btn{
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-table;
}
.btn span{
    margin-left: 10px;
}
.btn-steam{background-color: var(--steam-color);}
.btn-xbox{background-color: var(--xbox-color);}
.btn-epic{background-color: var(--epic-color);}
.btn-itch{background-color: var(--itch-color);}
.btn-playstore{background-color: var(--playstore-color);}

.container-vertical iframe{
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
}
#social-media-btn-list{
    list-style: none;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top:30px;
    margin-bottom: 30px;
}
.btn-socialmedia{
    width: 50px;
    height: 50px;
    padding: 20px;
    border-radius: 10px;
    margin-right: auto;
    margin-left: 5px;
    margin-right: 5px;
    background-color: var(--btn-social-media-color);
}
.last-line{
    display:table;
    margin-right:auto;
    margin-left:auto;
}
.video-iframe{
    width:50%
}
/* SCREEN QUERY ---------------------------------- */
@media only screen and (1050px > width){
    .btn-list{
        display: inline-table;
    }    
}
@media only screen and (750px > width) {
    .Game{
        margin-top: 4rem;
    }
    .container-horizontal{
        display: block;
        margin-bottom: 10px;
    }
    .div-image, .div-short-text{
        width: 100%;
        margin-bottom: 10px;
    }
    .short-text-container{
        height: 80%;
        width: 80%;
    }
    .picture_image_shortheader{
        height: 20%;
        width: 80%;
    }
    .quick-carousel{
        margin-left: 0px;
        margin-right: 0px;
    }

    .video-iframe{
        width:80%
    }
}
/* --------------privacy policies---------- */
.sticky-material{
    position: fixed;
    display: flex;
    width: 100%;
    padding-left:  10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: rgba(39, 5, 46, 0.301);
    backdrop-filter: blur(3px);
}
.icon-policies{
    display: table;
    width: 64px;
    height: 74px;
    object-fit: cover;
}
.title-fixed{
    margin-left: 10px;
    margin-top: 20px;
}
.privacy_page{
        margin: 0px;
        background-color: var(--privacy_bg);
        height: auto;
        width: auto; 
}

.privacy_header{
    display:block;
    width: auto;
    height: auto;
}
.privacy_header_img{
    object-fit: cover;
    width: 100%;
    height: 20vw;
}

.body-privacy{
    width: 90vw;
    margin-left: auto;
    margin-right: auto;
}
.body-privacy h1{
    text-align: left;
    color: var(--privacy_title_font);
}

.body-privacy span, .body-privacy h3, .body-privacy ol li{
    text-align:left !important;
    color: var(--privacy_normal_font) !important;
}

.body-privacy ol{
    gap: 10px;
}
.body-privacy ol li{
    font-size: 14;
}