:root{
    --shade-modal:rgba(0, 0, 0, 0.856);
}

*{
    margin: 0
}

.modal-page{
    position:fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: 0.3s ease-in-out;
}

.modal-page:has(input[type="checkbox"]:checked){
    z-index: 11;
    opacity: 1;
    
    .modal-body{
        transform: translateY(0);
        opacity: 1;
    }
}
.modal-page:focus{
    & .modal-body{
        background-color: yellow;
    }    
}
.modal-shade{
    position:fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: var(--shade-modal);
    backdrop-filter: blur(5px);
    transition: 0.3s ease-in-out;
    mask: 
    linear-gradient(
        90deg,
        transparent,
        white ,
        white ,
        transparent 
    );
}

.modal-body{
    transition: 0.5s ease-in-out;
    transform: translateY(-200px);
    opacity: 0;
    position:fixed;
    width: 50vw;
    height: 70vh;
    margin-top: 10vh;
    position: relative;
    border-radius: 20px;
    background-color:rgb(7, 7, 14);
    display: table;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    & .modal-header {
        display: inline-flex;
        width: 100%;
        background-color: rgb(5, 2, 15);
        border-bottom: solid 1px rgb(47, 25, 67);
    }
    & .modal-header h1{
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
    }
    & .modal-header button{
        background-color: rgb(6, 4, 22);
        border: 0;
        width: 40px;
    }
    & .modal-header button:hover{
        background-color: rgb(17, 12, 56);
    }
    & .modal-content{
        display: inline-block;
        width: 100%;
        height: 430px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
    & .modal-footer {
        padding: 10px 30px;
        display: flex;
        width: 100%;
        gap: 50px;
        border-top: solid 1px rgb(150, 150, 150);
    }
    & .modal-footer button{
        border: 0;
        display: table;
        padding: 5px 15px;
        border-radius: 10px;
        background-color: rgb(18, 13, 28);
    }
    & #btn-goto{
        display:table;
    }
    & .modal-footer-btn-container{
        display: flex;
        gap: 50px;
        margin-left: auto;
        margin-right: 10%;
    }
    & .modal-footer button:hover{
        background-color: rgb(40, 30, 62);
    }
}

.modal-carousel-container{
    position: relative;
    display: inline-block;
    background-color:rgb(0, 0, 0);
    width: 100%;
    height:300px
}
.modal-body-content{
    text-align: left;
    padding: 10px 15px;
}
.generic-project-btn{
    border-radius: 20px;
    border-style: dashed;
    padding: 5px 15px;
    background-color: rgb(255, 255, 255,0);
    border: 1px solid rgba(255, 255, 255, 1);
    transition: 0.3s ease-in-out;
}
.generic-project-btn:hover{
    background-color: rgba(96, 55, 132, 0.283);
    transform: scale(1.1);
}


.modal-carousel-container{
    display: flex;
    & button{
        width: 5%;
        border: 0px;
        background:
            linear-gradient(
                120deg,
                rgb(14, 4, 43) 10%,
                black 70%
            );
        transition: 0.3s ease-in-out;
        cursor: pointer;
    };
    & button:hover{
        font-size: 20px;
        background:
            linear-gradient(
                120deg,
                rgb(27, 10, 80) 10%,
                rgb(24, 1, 18) 70%
            );
    }
    & .carousel-body{
        width: 90%;
        height: 90%;
    }
    & .modal-carousel-images{
        display: inline-flex;
        position: relative;
        width: 100%;
        height: 110.5%;
        background-color: rgb(9, 3, 3);
        overflow: hidden;
        gap: 5px
    }
    & .images-indicator{
        position:absolute;
        /* display: none; */
        width: 90%;
        height: 10%;
        margin-top: -4.1%;
        background-color: rgba(2, 2, 27, 0.509);
        backdrop-filter: blur(5px);
        mask: 
            linear-gradient(
                90deg,
                transparent,
                white,
                white,
                transparent
            );
    }
}

.focused-img{
    width: 90%;
    height: 100%;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    background-color: rgb(24, 23, 24);
    transform: 0.3s ease-in-out;
    filter: blur(0) brightness(1);

    & img{
        display: inline-block;
        object-fit: cover;
        width: 100%;
        height: 100%
    }
    
}
.normal-img{
    width: 5%;
    height: 100%;
    border-radius: 10px;
    background-color: rgb(24, 23, 24);
    overflow: hidden;
    transform: 0.3s ease-in-out;
    filter: hue-rotate(130deg) saturate(30%) brightness(0.2);
    & img{
        display: inline-block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
} 

.images-indicator{
    & ul{
        list-style-type: none;
        display: inline-flex;
        margin-left: auto;
        margin-right: auto;
        gap: 10px;
    }
    & ul .btn-to-select-img label{
        display: inline-block;
        width: 15px;
        height: 5px;
        border-radius: 5px;
        background-color: #b17f7f;
        cursor: pointer;
        transition: 0.3s ease-in-out;
    }
    & ul .btn-to-select-img label:hover{
        background-color: #d8a6a6;
    }
    
}
.btn-to-select-img:has(input[type="radio"]:checked){
    & label{
        transform: translateY(-3px);
        background-color: white;
    }
}


/* changesss */
.central_to_right{
    transition: 0.3s ease-in-out;
    transform: translateX(-50%);
    width: 50%;
    filter: brightness(0.1) blur(3px);
    
}

.central_to_left{
    transition: 0.3s ease-in-out;
    transform: translateX(50%);
    width: 50%;
    filter: brightness(0.1) blur(3px);
}

.right_to_right{
    width: 75%;
    transition: 0.3s ease-in-out;
    transform: translateX(-50%) scale(1.1);
    filter: brightness(0.1) blur(3px);
    margin-left: 20px;
}

.left_to_left{
    width: 75%;
    transition: 0.3s ease-in-out;
    transform: translateX(50%) scale(1.1);
    filter: brightness(0.1) blur(3px);
    margin-right: 20px;
}

.blink{
    transition: 0.3s ease-in-out;
    filter: brightness(0.1);
    transform: scale(0.5) translateY(25%);
}


@media only screen and (width < 650px){
    .modal-body{
        width: 80%;
    }
}