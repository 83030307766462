:root{
    --border-search-option: 1px dashed rgb(41, 127, 143);
}

.search-body{
    margin-top: 12px;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(2%);
    & input[type="search"]{
        transition: 0.3 ease-in-out;
        width: 100%;
        margin-left: 0px;
        padding: 5px 20px;
        background-color: rgba(6, 1, 9, 0.482);
        backdrop-filter: blur(5px);
        border-radius: 50px;
        border-width: 1px;
        border-style:ridge;
        border-color: rgb(20, 47, 111);
        transition: 0.3s ease-in-out;

    }
    & input:hover{
        outline-style: solid;
        outline-width: 2px;
        outline-color: rgba(210, 46, 123, 0.673);
    }
    & input:focus{
        outline-style: dashed;
        outline-width: 2px;
        outline-color: rgba(184, 252, 100, 0.673);
    }
    & .icon-search{
        /* transform: translateX(30px); */
        display: inline-table;
        margin-left: -25px;
        margin-bottom: -3px;
    }
}

.search-body:has(input[type="search"]:focus){
    background-color: rgba(255, 255, 255,0);
    filter: blur(0);
    .icon-search{
        color: rgb(202, 202, 97);
        scale: 1.1;
    }
}

.search-list{
    position: fixed;
    width: 98%;
    transform: translateX(3%);
    height: auto;
    min-height: 20px;
    max-height: 250px;
    background-color: rgba(169, 14, 120, 0.868);
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
    margin-top: -10px;
    border-radius: 0px 0px 10px 10px;
    overflow-y: auto;
    overflow-x: hidden;
    background:
        linear-gradient(
            140deg,
            rgba(255, 0, 55, 0.33) 10%,
            rgba(77, 6, 82, 0.644) 90%
        );
}

.option-body{
    width: 100%;
    height: 30px;
    backdrop-filter: blur(10px);
    background-color: rgba(117, 35, 193, 0.206);
    border-top: var(--border-search-option);
    display: flex;
    transition: 0.3s ease-in-out;
    background:
    linear-gradient(
        110deg,
        rgba(229, 43, 83, 0.153) 10%,
        rgba(34, 6, 82, 0.434) 90%
    );

    & picture {
        width: 10%;
        padding-left: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        & img{
            width: 25px;
            height: 25px;
            border-radius: 30px;
            background-color:rgb(0, 0, 0);
            object-fit: cover;
        }
    }
    & small{
        width: 80%;
        padding-top: 4px;
        font-size: 10px;
    }
    & svg{
        width: 10%;
        padding-top: 4px;
        padding-right: 4px;
    }

    &:hover{
    background-color: #c72453ba;

    }
}


@media only screen and (width < 650px) {
   .search-body input[type="search"]{
        width: 80%;
        margin-left: 40px;
    }
    .search-list{
        width: 80%;
        margin-left: 40px;
        transform: translateX(4%);
    }
}