:root{
    --games-bg: rgba(255,255,255,0.5);
}

*{
    margin: 0;
}

.gamespage{
    position: absolute;
    background-image: url(../../assets/images/games_section.webp);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   background-attachment: fixed;
    &::before {
        content: "";
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background:
            linear-gradient(
                135deg,
                rgba(255, 0, 0, 0) 10%,
                rgba(8, 1, 21, 0.806) 70%
            );
    }
}

.gameList{
    width: 70%;
    height:auto;
    min-height: 80px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    position: relative;
    z-index: 5;
    border-radius: 15px;
    display: inline-table;
    background:
    linear-gradient(
        135deg,
        rgba(255, 0, 0, 0) 10%,
        rgba(15, 2, 41, 0.806) 70%
    );
    backdrop-filter: blur(2px);
    box-shadow: 3px 5px 7px 2px rgba(28, 25, 111, 0.649);
}

.responsive-list{
    gap: 20px;
    position: relative;
    width: 100%;
    height: auto;
}

.title-section{
    font-size: 18px;
}

@media only screen and (width < 650px) {
    .gameList{
        margin-top: 7rem;
    }
}