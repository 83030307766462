:root{
    --card-game: rgba(36, 13, 45, 0.487);
}

.game_card{
    width: auto;
    height: auto;
    position: relative;;
    display: inline-block;
    margin: 5px;
    & picture{
        border-radius: 20px;
        width: clamp(100px,250px, 500px);
        height:clamp(100px,250px, 500px);
        /* background-color: rgb(13, 2, 18); */
        display: table-cell;
        overflow: hidden;
        border: 2px dashed  rgb(151, 103, 228);
    }
    & picture img{
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
        transition: 0.3s ease-in-out;
        margin-bottom: -27px;
    }
   
    & .description{
        transition: 0.3s ease-in-out;
        position: absolute;
        width: clamp(90px,240px, 490px);
        font-size: clamp(10px, 12px, 16px);
        transform: translateX(-50%) translateY(30px);
        opacity: 0;
        z-index: 1;
    }


    & picture span{
        position:sticky;
        display: block;
        z-index: 1;
        padding: 3px 3px;
        width: auto;
        background-color: rgba(36, 6, 61, 0.616);
        backdrop-filter: blur(5px);  
        mask:
            linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.277),
                rgb(255, 255, 255),
                white,
                rgba(255, 255, 255, 0.277)
            );
    }

    & picture:hover{ 
        img{
            transform: scale(1.1);
            filter: brightness(0.2) blur(2px);
        }
        .description{
            opacity: 1;
        }
    }
}