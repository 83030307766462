:root{
    border-color: rgb(49, 118, 65);
}
*{
    margin: 0;
}

.card-body {
    position: relative;
    display: inline-block;
    background-color: rgba(31, 29, 36, 0.406);
    backdrop-filter: blur(5px);
    width: 250px;
    height: 350px;
    margin: 10px;
    border-radius: 10px;
    border: 1px;
    border-style: dotted;
    border-color: var(--background-color);
    overflow: hidden;
    transition: 0.3s ease-in-out;
    
    & picture{
        position: relative;
        display: inline-block;
        width: 100%;
        height: 230px;
        overflow: hidden;
        background:
        linear-gradient(
            145deg,
            rgb(34, 20, 20) 20%,
            black 90%   
        );
    }
    & picture .image_prev, & picture .image_changing{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition:  0.3s ease-in-out;
    }
    & picture .image_changing{
        translate: 50px -10px;
        opacity: 0.4;
        transform: rotateY(90deg);
    }
    &:hover picture img, &:hover picture .image_prev{
        scale: 1.1
    }

    & .body-container{
        padding: 5px;
    }
    & .body-container .info-header{
        display: flex;
        width: 100%;
        height: auto;
    }

    & button {
        margin-top: 10px;
        border-radius: 50px;
        border-style: dashed;
        border-color: rgba(52, 97, 62, 0.6);
        transition: 0.3s ease-in-out;
        background:
            linear-gradient(
                145deg,
                rgba(97, 52, 66, 0.6) 10%,
                rgba(33, 1, 44, 0.6) 90%
        );
    }
    & button:hover {
        background:
        linear-gradient(
            145deg,
            rgba(172, 92, 118, 0.6) 10%,
            rgba(34, 15, 130, 0.6) 90%
         );
    }
    &:hover{
        transform: translateY(-5px);
        box-shadow: 2px 5px 5px 1px var(--border_light);
        background-color: rgba(50, 45, 64, 0.916);
        
    }
}
.info-header{
    & picture{
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
    }
    & picture img{
        position: relative;
        display: inline-block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: 0.3s ease-in-out;
    }
    & span{
        position: relative;
        display: table;
        margin-left: auto;
        margin-right: auto;
        padding-top: 4%;
    }
}