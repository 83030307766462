:root{
    --bg-arts: red;
}

*{
    margin:0;
}

.art-page{
    position: absolute;
    width: 100%;
    height: auto;
    background-image: url(../../assets/images/arts-section.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    background-attachment: fixed;
    &::before {
        content:"";
        height: 100%;
        width: 100%;
        position: fixed;
        display: block;
        background-attachment: fixed;
        background:
        linear-gradient(
            135deg,
            rgba(65, 3, 42, 0.3) 10%,
            rgba(2, 0, 24, 0.707) 80%
        );
    }
}

.title-section{
    font-size: 18px;
}

.container-arts{
    width: 90%;
    height:auto;
    min-height: 80px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    position: relative;
    z-index: 5;
    border-radius: 15px;
    display: inline-table;
    background:
    linear-gradient(
        135deg,
        rgba(255, 0, 0, 0) 10%,
        rgba(15, 2, 41, 0.806) 70%
    );
    backdrop-filter: blur(2px);
    box-shadow: 3px 5px 7px 2px rgba(28, 25, 111, 0.649);

}


@media only screen and (width < 650px) {
    .container-arts{
        margin-top: 7rem;
        background-color: rgba(255, 0, 0, 0.189);
    }
}