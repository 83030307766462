:root{
    --bg-color-project: rgba(255,255,255,0.8);
    --bg-card-container-project: rgba(19, 1, 31, 0.772);
}

*{
    margin: 0%;
}

.projects-main{
    background-color: var(--bg-color-project);
    width: 100%;
    position: absolute;
    height: auto;
    background-image: url('../../assets/images/projects_section.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    &::before{
        content: "";
        position:fixed;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;
        margin-top: 0;
        background:
            linear-gradient(
                140deg,
                rgba(43, 10, 78, 0.368) 30%,
                rgba(12, 1, 25, 0.95) 90%
            );
    }
}

.projects-container{
    position: relative;
    display: inline-table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    width: 80%;
    height: auto;
    padding: 1rem 0rem;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    background: 
    linear-gradient(
        160deg,
        var(--bg-card-container-project) 10%,
        rgba(3, 0, 11, 0.79)90%
    );
    box-shadow: 5px 5px 7px 2px var(--border_light);
    & h1{
        font-size: 18px;
    }
}

.card-container{
    gap:   20px;
    position: relative;
    width: 100%;
    height: auto;
}

@media only screen and (width < 650px){
    .projects-container{
        margin-top: 7rem;
    }
}