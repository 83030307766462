*{
    margin: 0;
}

.modal-item{
    transition: 0.3s ease-in-out;
    position: fixed;
    display: block;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba(14, 3, 26, 0.713); 
    backdrop-filter: blur(2px);
}
.shade-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 2, 12, 0.702); 
    mask: 
    linear-gradient(
        90deg,
        transparent 10%,
        white 40%,
        white 40%,
        transparent
    );

}

.modal-container{
    width: 85%;
    height: 90%;
    margin-left: auto;
    margin-right: auto;
    transition: 0.5s ease-in-out;
    opacity: 0;
    transform: translateY(-70px);
    margin-top: 15px;

    & button{
        overflow: hidden;
        font-size: 14px;
        position: fixed;
        display: table;
        background-color: rgba(20, 7, 44, 0.243);
        text-align: center;
        border-radius: 50px;
        border: solid 1px rgb(127, 118, 171);
        backdrop-filter: blur(5px);
        transition: 0.3s ease-in-out;
        right: 0;
        z-index: 1;
    }
    & button:hover{
        background-color: rgba(54, 26, 107, 0.243);
    }
}
    
 .modal-container-img{
        position: relative;
        display: inline-flex;
        margin-top: 1rem;
        width: 100%;
        height: 100%;
        
}

.modal-container-img img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit:contain;
    filter:  drop-shadow( 0px 3px 5px rgba(120, 35, 154, 0.7));
}

.modal-item:has(input[type="checkbox"]:checked){
    backdrop-filter: blur(2px);
    z-index: 11;

    & .modal-container{
        opacity: 1;
        transform: translateY(0);
    }
}