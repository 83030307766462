.bg-experience{
    width: 100%;
    min-height: 100%;
    height: auto;
    position: absolute;
    background-image: url(../images/experience-section.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        background: 
            linear-gradient(
                130deg,
                rgba(255, 0, 0, 0.131) 10%,
                rgba(1, 1, 42, 0.8) 90%);
    }
}

.container-experience{
    width: 80%;
    height: auto;
    min-height: 100px;
    position: relative;
    display: block;
    border-radius: 10px;
    padding: 1rem 2rem;
    background: 
    linear-gradient(
        270deg,
        rgba(255, 0, 0, 0.551) 10%,
        rgba(2, 2, 34, 0.24) 90%
        );
    
    backdrop-filter: blur(3px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
    box-shadow: 5px 4px 5px 2px rgba(117, 24, 73, 0.659);
}

.title-section{
    font-size: 18px;
}

@media only screen and (width < 650px){
    .container-experience{
        margin-top: 7rem;
    }
}
